<template>
  <v-container
    id="accueil"
    class="fill-height"
    fluid
    tag="section"
  >
    <v-card
      class="fill-height ma-0"
      style="width:100%"
    >
      <v-card-title>
        <v-row
          justify="center"
          class="mt-15"
        >
          <v-col
            xl="1"
            lg="1"
            md="2"
            sm="2"
            cols="4"
          >
            <img
              src="@/assets/toucan.svg"
              alt="Toucan"
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row
          class="my-10 fill-height"
          justify="center"
          align="center"
        >
          <h1
            class="title"
          >
            {{ $t('deconnexion.etes_vous_certain_de_vouloir_vous_deconnecter') }}
          </h1>
        </v-row>
        <v-row
          class="mt-10"
          justify="center"
        >
          <v-btn

            @click="annuler()"
          >
            <i class="fas fa-comment-dots" />&#160;{{ $t('general.non') }}
          </v-btn>
          <v-btn

            @click="déconnecter()"
          >
            <i class="fas fa-comment-dots" />&#160;{{ $t('general.oui') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<style scoped>
    .title {
        font-size: 25px !important;
        text-align: center !important;
    }
</style>
<script>
  import toucanService from '@/services/toucanService.js'
  export default {
    name: 'DConnexion',

    data: () => ({
      evenement: {},
    }),
    created () {

    },
    methods: {
      annuler() {
        // retourner à la page précédente
        this.$router.back()
      },
      déconnecter () {
        const creePar = this.$store.state.userInfo.creePar
        const urlDeconnection = this.$store.state.userInfo.urlDeconnection
        // todo : J'aimerais centraliser cela dans une classe service.
        toucanService.déconnexion()

        if (urlDeconnection) {
          window.location = urlDeconnection
          return
        }

        // TODO : cela sera bientôt innutile car
        // c'est le champ urlDeconnection qui sera utilisé.
        // exemple :
        // update usager set usager.url_deconnection = 'https://fmoq.connexence.com' where usager.cree_par = 'Passerelle FMOQ';
        if (creePar === 'Passerelle FMOQ') {
          window.location = 'https://fmoq.connexence.com'
          return
        } else if (creePar === 'Passerelle APFF') {
          window.location = 'https://evenement-apff.connexence.com'
          return
        } else if (creePar === 'Passerelle AMSMNQ') {
          window.location = 'https://colloque-amsmnq.connexence.com'
          return
        } else if (creePar === 'Passerelle MDFC') {
          window.location = 'https://mdfc.connexence.com'
          return
        }

        this.$router.push({ path: '/' })
      },
    },
  }
</script>
